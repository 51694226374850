import React from "react";

import { useTranslation, Trans } from "react-i18next";

import cfd1 from '../../img/Home/cfd1.png'
import cfd2 from '../../img/Home/cfd2.png'
import cfd3 from '../../img/Home/cfd3.png'

function TradeCFDs () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row gap-[20px]">
                <div className="relative flex-shrink-0 h-[250px] rounded-[40px] overflow-hidden">
                    <img src={cfd1} alt="" className="object-cover w-full h-full"/>
                    <div className="absolute inset-[40px]">
                        <p className="text-black spanmain text-[16px] xl:text-[32px] manrope font-[500]"><Trans>{t('home.4')}</Trans></p>
                        <p className="roboto text-[14px] xl:text-[16px] text-black opacity-[0.5] max-w-[250px] mt-[20px]">{t('home.5')}</p>
                        <a href="https://user.asset-wealth.pro/">
                            <div className="flex items-center gap-[15px] absolute bottom-0 cursor-pointer">
                                <p className="roboto text-[14px] xl:text-[16px]">{t('home.3')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill="black"/>
                                    <path d="M7 12L17 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L18 12L13 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="relative h-[250px] w-full bg-[#145B45] rounded-[40px]">
                    <img src={cfd2} alt="" className="absolute right-0"/>
                    <div className="absolute inset-[40px]">
                        <p className="spanmainwhite text-[16px] xl:text-[32px] manrope font-[500] text-white"><Trans>{t('home.6')}</Trans></p>
                        <p className="roboto text-[14px] xl:text-[16px] text-white opacity-[0.5] max-w-[250px] mt-[20px]">{t('home.7')}</p>
                        <a href="https://user.asset-wealth.pro/">
                            <div className="flex items-center gap-[15px] absolute bottom-0 cursor-pointer">
                                <p className="roboto text-[14px] xl:text-[16px] text-white">{t('home.3')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill="white"/>
                                    <path d="M7 12L17 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L18 12L13 17" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row gap-[20px] mt-[20px]">
                <div className="relative h-[270px] w-full bg-[#145B45] rounded-[40px]">
                    <img src={cfd2} alt="" className="absolute right-0"/>
                    <div className="absolute inset-[40px]">
                        <p className="spanmainwhite text-[16px] xl:text-[32px] manrope font-[500] text-white leading-[1.2]"><Trans>{t('home.8')}</Trans></p>
                        <p className="roboto text-[14px] xl:text-[16px] text-white opacity-[0.5] max-w-[350px] mt-[20px]">{t('home.9')}</p>
                        <a href="https://user.asset-wealth.pro/">
                            <div className="flex items-center gap-[15px] absolute bottom-0 cursor-pointer">
                                <p className="roboto text-[14px] xl:text-[16px] text-white">{t('home.3')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill="white"/>
                                    <path d="M7 12L17 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L18 12L13 17" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="relative flex-shrink-0 h-[270px] rounded-[40px] overflow-hidden">
                    <img src={cfd3} alt="" className="object-cover w-full h-full"/>
                    <div className="absolute inset-[40px]">
                        <p className="text-black spanmain text-[16px] xl:text-[32px] manrope font-[500] leading-[1.2]"><Trans>{t('home.10')}</Trans></p>
                        <p className="roboto text-[14px] xl:text-[16px] text-black opacity-[0.5] mt-[20px]">{t('home.11')}</p>
                        <a href="https://user.asset-wealth.pro/">
                            <div className="flex items-center gap-[15px] absolute bottom-0 cursor-pointer">
                                <p className="roboto text-[14px] xl:text-[16px]">{t('home.3')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill="black"/>
                                    <path d="M7 12L17 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L18 12L13 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TradeCFDs;