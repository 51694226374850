import React from "react";

import { useTranslation, Trans } from "react-i18next";

import e from '../../img/Home/expirience.png'

function Experience () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row gap-[20px] justify-between">
                <div className="xl:mt-[60px]">
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black">{t('home.12')}</p>
                    <div className="flex items-center mt-[40px] gap-[30px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.377 7.10948L0.712729 13.2665L0.712732 0.952488L11.377 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('home.13')}</p>
                    </div>
                    <div className="flex items-center mt-[12px] gap-[30px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.377 7.10948L0.712729 13.2665L0.712732 0.952488L11.377 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('home.14')}</p>
                    </div>
                    <div className="flex items-center mt-[12px] gap-[30px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.377 7.10948L0.712729 13.2665L0.712732 0.952488L11.377 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('home.15')}</p>
                    </div>
                    <div className="flex items-center mt-[12px] gap-[30px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.377 7.10948L0.712729 13.2665L0.712732 0.952488L11.377 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('home.16')}</p>
                    </div>
                    <div className="flex items-center mt-[12px] gap-[30px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.377 7.10948L0.712729 13.2665L0.712732 0.952488L11.377 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('home.17')}</p>
                    </div>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={e} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Experience;