import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import Glance from "../components/AboutUs/Glance";
import OurValue from "../components/AboutUs/OurValue";
import What from "../components/AboutUs/What";
import Traders from "../components/AboutUs/Traders";
import WhatTheIndustry from "../components/AboutUs/WhatTheIndustry";

function AboutUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Glance/>
            <OurValue/>
            <What/>
            <Traders/>
            <WhatTheIndustry/>
        </div>
    );
}

export default AboutUs;