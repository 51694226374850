import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import sw1 from '../../img/Products/switcher/sw1.png'
import sw2 from '../../img/Products/switcher/sw2.png'
import sw3 from '../../img/Products/switcher/sw3.png'
import sw4 from '../../img/Products/switcher/sw4.png'
import sw5 from '../../img/Products/switcher/sw5.png'

import Button from '../Button'

const data = [
    {
        title: "products.23",
        text1: "products.28",
        text2: "products.29",
        img: sw1,
        text3: "products.30",
        text4: "products.31",
    },
    {
        title: "products.24",
        text1: "products.32",
        text2: "products.33",
        img: sw2,
        text3: "products.34",
        text4: "products.35",
        text5: "products.36",
        text6: "products.37",
        text7: "products.38",
        text8: "products.39",
        text9: "products.40",
        text10: "products.41",
        text11: "products.42",
    },
    {
        title: "products.25",
        text1: "products.43",
        text2: "products.44",
        img: sw3,
        text3: "products.45",
        text4: "products.46",
        text5: "products.47",
        text6: "products.48",
        text7: "products.49",
        text8: "products.50",
        text9: "products.51",
        text10: "products.52",
    },
    {
        title: "products.26",
        text1: "products.53",
        text2: "products.54",
        img: sw4,
        text3: "products.55",
        text4: "products.56",
        text12: "products.57",
        text13: "products.58",
        text5: "products.59",
        text6: "products.60",
        text7: "products.61",
        text8: "products.62",
        text9: "products.63",
        text10: "products.64",
        text11: "products.65",
    },
    {
        title: "products.27",
        text1: "products.66",
        text2: "products.67",
        img: sw5,
        text5: "products.68",
        text1Forex: "products.69",
        text2Forex: "products.70",
        text3Forex: "products.71",
        text4Forex: "products.72",
        text5Forex: "products.73",
        text6Forex: "products.74",
    },
]

function Productss () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between xl:items-end">
                <div>
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('products.18')}</Trans></p>
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('products.19')}</Trans></p>
                    <p className="roboto text-[16px] xl:text-[20px] text-[#000000B2] mt-[20px]">{t('products.20')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="roboto text-[20px] xl:text-[32px] text-[#000000B2]">{t('products.21')}</p>
                    <p className="mt-[20px] text-[16px] text-[#000000B2] roboto leading-[1.2] opacity-[0.7] max-w-[497px]">{t('products.22')}</p>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row mt-[20px] xl:mt-[60px] gap-[100px]">
                <div className="flex flex-col gap-[10px]">
                    {data.map((dat, index) => (
                        <div onClick={() => setActive(index)} key={index} className={`w-[310px] h-[72px] cursor-pointer flex items-center justify-between border border-[#145B45] rounded-full transition-all ${index === active ? 'bg-[#145B45]' : 'bg-white'}`}>
                            <p className={`ml-[30px] roboto text-[16px] xl:text-[20px] ${index === active ? 'text-white' : 'text-[#145B45]'}`}>{t(dat.title)}</p>
                            <svg className="mr-[20px]" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <path d="M1 6L11 6" stroke={index === active ? 'white' : '#145B45'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 1L12 6L7 11" stroke={index === active ? 'white' : '#145B45'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div> 
                    ))}
                </div>
                <div className="w-full">
                    <div className="flex flex-col xl:flex-row justify-between">
                        <div className="flex flex-col justify-between">
                            <p className="text-[20px] xl:text-[36px] leading-[1.2] font-[500] manrope text-[#145B45]"><Trans>{t(data[active].text1)}</Trans></p>
                            <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] max-w-[364px]">{t(data[active].text2)}</p>
                            <Button className="mt-[20px]"/>
                        </div>
                        <div className="mt-[20px] xl:mt-0">
                            <img src={data[active].img} alt="" />
                        </div>
                    </div>
                    <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[80px]">{t(data[active].text3)}</p>
                    <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[12px] opacity-[0.7]">{t(data[active].text4)}</p>
                    <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[40px]">{t(data[active].text12)}</p>
                    <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[12px] opacity-[0.7]">{t(data[active].text13)}</p>
                    <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[40px]">{t(data[active].text5)}</p>
                    <div className={`${data[active].text6 ? 'block' : 'hidden'} flex items-center gap-[30px] mt-[18px]`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.3769 7.10948L0.712638 13.2665L0.712641 0.952488L11.3769 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2]">{t(data[active].text6)}</p>
                    </div>
                    <div className={`${data[active].text7 ? 'block' : 'hidden'} flex items-center gap-[30px] mt-[10px]`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.3769 7.10948L0.712638 13.2665L0.712641 0.952488L11.3769 7.10948Z" fill="#145B45"/>
                        </svg>
                        <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2]">{t(data[active].text7)}</p>
                    </div>
                    <div className={`${data[active].text1Forex ? 'block' : 'hidden'} flex items-start gap-[30px] mt-[18px]`}>
                        <svg className="mt-[7px] flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.3769 7.10948L0.712638 13.2665L0.712641 0.952488L11.3769 7.10948Z" fill="#145B45"/>
                        </svg>
                        <div className="flex flex-col">
                            <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#145B45]">{t(data[active].text1Forex)}</p>
                            <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[12px] opacity-[0.7]">{t(data[active].text2Forex)}</p>
                        </div> 
                    </div>
                    <div className={`${data[active].text1Forex ? 'block' : 'hidden'} flex items-start gap-[30px] mt-[18px]`}>
                        <svg className="mt-[7px] flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.3769 7.10948L0.712638 13.2665L0.712641 0.952488L11.3769 7.10948Z" fill="#145B45"/>
                        </svg>
                        <div className="flex flex-col">
                            <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#145B45]">{t(data[active].text3Forex)}</p>
                            <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[12px] opacity-[0.7]"><Trans>{t(data[active].text4Forex)}</Trans></p>
                        </div> 
                    </div>
                    <div className={`${data[active].text1Forex ? 'block' : 'hidden'} flex items-start gap-[30px] mt-[18px]`}>
                        <svg className="mt-[7px] flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.3769 7.10948L0.712638 13.2665L0.712641 0.952488L11.3769 7.10948Z" fill="#145B45"/>
                        </svg>
                        <div className="flex flex-col">
                            <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#145B45]">{t(data[active].text5Forex)}</p>
                            <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[12px] opacity-[0.7]">{t(data[active].text6Forex)}</p>
                        </div> 
                    </div>
                    <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[40px]">{t(data[active].text8)}</p>
                    <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[12px] opacity-[0.7]">{t(data[active].text9)}</p>
                    <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[6px] opacity-[0.7]">{t(data[active].text10)}</p>
                    <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[6px] opacity-[0.7]">{t(data[active].text11)}</p>
                </div>
            </div>
        </div>
    );
}

export default Productss;