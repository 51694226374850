import React from "react";

import { useTranslation, Trans } from "react-i18next";

import vec1 from '../../img/Security/vec3.png'

function IsRight () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50">
                <div className="flex flex-col bg-[#145B45] xl:h-[457px] rounded-[40px] relative p-[30px] xl:p-[60px]">
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-white"><Trans>{t('security.18')}</Trans></p>
                    <p className="max-w-[540px] roboto text-[14px] xl:text-[16px] opacity-[0.7] text-[#FFFFFFB2] leading-[1.4] mt-[20px]">{t('security.19')}</p>
                    <p className="max-w-[540px] roboto text-[14px] xl:text-[16px] opacity-[0.7] text-[#FFFFFFB2] leading-[1.4] mt-[20px]">{t('security.20')}</p>
                    <img src={vec1} alt="" className="xl:absolute right-0"/>
                </div>
            </div>
        </div>
    );
}

export default IsRight;