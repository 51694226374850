import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Products/main.png'
import main_mob from '../../img/Products/main_mob.png'
import main_tab from '../../img/Products/main-tab.png'

import Button from '../Button'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative h-[763px]">
            <img src={main} alt="" className="hidden md:block absolute left-1/2 -translate-x-1/2 object-cover"/>
            <img src={main_mob} alt="" className="block sm:hidden w-full absolute h-full object-cover"/>
            <img src={main_tab} alt="" className="hidden sm:block md:hidden w-full absolute h-full object-cover"/>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative">
                <p className="manrope text-[32px] font-[600] leading-[1.2] xl:text-[64px] uppercase mt-[80px] xl:mt-[145px]"><Trans>{t('products.1')}</Trans></p>
                <p className="roboto text-[16px] xl:text-[20px] text-[#000000B2] max-w-[540px] mt-[20px] xl:mt-[40px]">{t('products.2')}</p>
                <p className="mt-[20px] xl:mt-[30px] max-w-[497px] text-[#000000B2] opacity-[0.7] roboto text-[14px] xl:text-[16px] leading-[1.4]">{t('products.3')}</p>
                <Button className="mt-[60px]"/>
            </div>
        </div>
    );
}

export default Main;