import React from "react";

import { useTranslation, Trans } from "react-i18next";

import cfd1 from '../../img/AboutUs/cfd1.png'
import cfd2 from '../../img/AboutUs/cfd2.png'
import cfd3 from '../../img/AboutUs/cfd3.png'

function Glance () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative">
                <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('aboutus.4')}</Trans></p>
                <div className="flex flex-col xl:flex-row mt-[20px] xl:mt-[60px] gap-[20px]">
                    <div className="relative h-[250px] w-full bg-[#145B45] rounded-[40px]">
                        <img src={cfd1} alt="" className="absolute right-0"/>
                        <div className="absolute inset-[40px]">
                            <p className="text-[16px] xl:text-[24px] manrope text-white font-[300]"><Trans><span className="text-[32px] xl:text-[64px] mr-[10px]">100,000+</span>{t('aboutus.5')}</Trans></p>
                            <p className="roboto text-[14px] xl:text-[16px] text-white opacity-[0.5] max-w-[433px] mt-[20px] font-[300]">{t('aboutus.6')}</p>
                        </div>
                    </div>
                    <img src={cfd2} alt="" />
                </div>
                <div className="flex flex-col xl:flex-row mt-[20px] gap-[20px]">
                    <div className="relative h-[280px] w-full bg-[#EBEBEB] rounded-[40px]">
                        <div className="absolute inset-[40px]">
                            <p className="spanmainwhite text-[16px] xl:text-[24px] manrope font-[300] text-[#000]"><Trans>{t('aboutus.7')}</Trans></p>
                            <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] opacity-[0.7] max-w-[358px] mt-[20px] font-[300]">{t('aboutus.8')}</p>              
                        </div>
                    </div>
                    <div className="relative h-[280px] w-full bg-[#145B45] rounded-[40px]">
                        <img src={cfd3} alt="" className="absolute right-0 bottom-0"/>
                        <div className="absolute inset-[40px]">
                        <p className="text-[16px] xl:text-[24px] manrope text-white font-[300]"><Trans><span className="text-[32px] xl:text-[64px] mr-[10px]">1,000+</span>{t('aboutus.9')}</Trans></p>
                            <p className="roboto text-[14px] xl:text-[16px] text-white opacity-[0.5] max-w-[433px] mt-[20px] font-[300]">{t('aboutus.10')}</p>           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Glance;