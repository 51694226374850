import React, { useState, useEffect } from "react";

import { useTranslation, Trans } from "react-i18next";

import i1 from '../../img/Education/into1.png'
import i2 from '../../img/Education/into2.png'

function Introduction () {

    const { t } = useTranslation();

    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);

    const toggleModal = () => {
        setActive1(!active1);
    };

    const toggleModal2 = () => {
        setActive2(!active2);
    };

    useEffect(() => {
        if (active1) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [active1]);

      useEffect(() => {
        if (active2) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [active2]);
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between xl:items-end">
                    <div>
                        <p className="max-w-[524px] manrope text-[24px] xl:text-[48px] leading-[1.4]">{t('education.5')}</p>
                    </div>
                    <div className="mt-[10px] xl:mt-0">
                        <p className="max-w-[497px] roboto text-[14px] xl:text-[16px] leading-[1.5] text-[#000000B2]">{t('education.6')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row gap-[20px] mt-[20px] xl:mt-[60px]">
                    <div className="xl:w-1/2 h-[564px] rounded-[40px] bg-[#EBEBEB] p-[30px] xl:p-[40px] relative">
                        <img src={i1} alt="" className="w-full h-[215px] object-cover rounded-[20px] overflow-hidden"/>
                        <p className="mt-[20px] xl:mt-[40px] text-[#000000B2] manrope text-[16px] xl:text-[24px] font-[500]">{t('education.7')}</p>
                        <p className="mt-[16px] text-[14px] xl:text-[16px] font-[300] roboto text-[#000000B2]">{t('education.8')}</p>
                        <a href="https://user.asset-wealth.pro/signup" className="absolute bottom-[40px]">
                            <div className="flex items-center gap-[16px]">
                                <p className="roboto text-[14px] xl:text-[16px] text-[#145B45] font-[500]">{t('education.11')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill="#145B45"/>
                                    <path d="M7 12L17 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L18 12L13 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                    <div className="xl:w-1/2 h-[564px] rounded-[40px] bg-[#EBEBEB] p-[30px] xl:p-[40px] relative">
                        <img src={i2} alt="" className="w-full h-[215px] object-cover rounded-[20px] overflow-hidden"/>
                        <p className="mt-[20px] xl:mt-[40px] text-[#000000B2] manrope text-[16px] xl:text-[24px] font-[500]">{t('education.9')}</p>
                        <p className="mt-[16px] text-[14px] xl:text-[16px] font-[300] roboto text-[#000000B2]">{t('education.10')}</p>
                        <a href="https://user.asset-wealth.pro/signup" className="absolute bottom-[40px]">
                            <div className="flex items-center gap-[16px]">
                                <p className="roboto text-[14px] xl:text-[16px] text-[#145B45] font-[500]">{t('education.11')}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect width="24" height="24" rx="12" fill="#145B45"/>
                                    <path d="M7 12L17 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L18 12L13 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduction;