import React from "react";

import { useTranslation, Trans } from "react-i18next";

import vec1 from '../../img/Security/vec2.png'

function Negative () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative">
                <div className="flex flex-col xl:flex-row-reverse mt-[20px] justify-between items-center">
                    <div>
                        <p className="manrope leading-[1.2] text-[20px] xl:text-[36px] text-black spanmain"><Trans>{t('security.13')}</Trans></p>
                        <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('security.14')}</Trans></p>
                        <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] opacity-[0.7] text-[#000000B2] leading-[1.4] mt-[40px]">{t('security.15')}</p>
                        <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] opacity-[0.7] text-[#000000B2] leading-[1.4] mt-[20px]">{t('security.16')}</p>
                        <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] opacity-[0.7] text-[#000000B2] leading-[1.4] mt-[20px]">{t('security.17')}</p>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <img src={vec1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Negative;