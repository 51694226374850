import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Education/main.png'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative mt-[10px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] p-[30px] xl:pt-[60px] xl:pl-[100px] flex flex-col bg-[#145B45] rounded-[40px] xl:h-[650px] relative">
                <p className="leading-[1.2] manrope text-[32px] xl:text-[64px] text-white"><Trans>{t('education.1')}</Trans></p>
                <p className="leading-[1.2] manrope text-[20px] xl:text-[36px] text-[#FFFFFF99] mt-[10px]"><Trans>{t('education.2')}</Trans></p>
                <p className="max-w-[575px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2] opacity-[0.7] mt-[20px] xl:mt-[40px]">{t('education.3')}</p>
                <p className="max-w-[575px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2] opacity-[0.7] mt-[20px]">{t('education.4')}</p>
                <img src={main} alt="" className="mt-[20px] xl:mt-0 xl:absolute right-0 top-0"/>
            </div>
        </div>
    );
}

export default Main;