import React from "react";

import { useTranslation, Trans } from "react-i18next";

import s1 from '../../img/AboutUs/s1.png'
import s2 from '../../img/AboutUs/s2.png'
import s3 from '../../img/AboutUs/s3.png'
import s4 from '../../img/AboutUs/s4.png'

function WhatTheIndustry () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative">
                <p className="manrope leading-[1.4] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('aboutus.29')}</Trans></p>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px] gap-[10px] xl:gap-0">
                    <div className="xl:w-[269px] h-[327px] flex flex-col bg-[#EBEBEB] rounded-[20px] items-center justify-between">
                        <img src={s1} alt="" />
                        <p className="text-center max-w-[180px] manrope text-[16px] font-[500] text-[#000000B2] pb-[30px]">{t('aboutus.30')}</p>
                    </div>
                    <div className="xl:w-[269px] h-[327px] flex flex-col bg-[#EBEBEB] rounded-[20px] items-center justify-between">
                        <img src={s2} alt="" />
                        <p className="text-center max-w-[180px] manrope text-[16px] font-[500] text-[#000000B2] pb-[30px]">{t('aboutus.31')}</p>
                    </div>
                    <div className="xl:w-[269px] h-[327px] flex flex-col bg-[#EBEBEB] rounded-[20px] items-center justify-between">
                        <img src={s3} alt="" />
                        <p className="text-center max-w-[180px] manrope text-[16px] font-[500] text-[#000000B2] pb-[30px]">{t('aboutus.32')}</p>
                    </div>
                    <div className="xl:w-[269px] h-[327px] flex flex-col bg-[#EBEBEB] rounded-[20px] items-center justify-between">
                        <img src={s4} alt="" />
                        <p className="text-center max-w-[180px] manrope text-[16px] font-[500] text-[#000000B2] pb-[30px]">{t('aboutus.33')}</p>
                    </div>
                </div>
            </div>
        </div>
    );                    
}

export default WhatTheIndustry;