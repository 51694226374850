import React from "react";

import { useTranslation, Trans } from "react-i18next";

import pricing from '../../img/Products/pricing.png'

function Pricing () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col justify-between">
                        <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('products.86')}</Trans></p>
                        <p className="text-[16px] xl:text-[20px] leading-[1.5] font-[400] roboto text-[#000000B2] mt-[16px] max-w-[393px]">{t('products.87')}</p>
                        <p className="text-[14px] xl:text-[16px] leading-[1.5] font-[400] roboto text-[#000000B2] max-w-[497px] opacity-[0.7] mt-[16px] xl:mt-[40px]">{t('products.88')}</p>
                        <img src={pricing} alt="" className="mt-[20px] xl:mt-[60px]"/>
                    </div>
                    <div className="xl:w-[601px] mt-[20px] xl:mt-0">
                        <div className="flex items-center border-b border-[#CCCCCC]">
                            <div className="w-[300px]">
                                <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2]">{t('products.89')}</p>
                                <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] max-w-[253px] opacity-[0.7] mt-[6px]">{t('products.90')}</p>
                            </div>
                            <div className="w-px h-[200px] xl:h-[150px] bg-[#CCCCCC]"></div>
                            <div className="w-[300px]">
                                <p className="ml-[30px] manrope text-[20px] xl:text-[24px] font-[500] text-[#145B45]">{t('products.91')}</p>
                            </div>  
                        </div>
                        <div className="flex items-center border-b border-[#CCCCCC]">
                            <div className="w-[300px]">
                                <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2]">{t('products.92')}</p>
                                <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] max-w-[253px] opacity-[0.7] mt-[6px]">{t('products.94')}</p>
                            </div>
                            <div className="w-px h-[200px] xl:h-[150px] bg-[#CCCCCC]"></div>
                            <div className="w-[300px]">
                                <p className="ml-[30px] manrope text-[20px] xl:text-[24px] font-[500] text-[#145B45]">{t('products.93')}</p>
                                <p className="ml-[30px] text-[14px] xl:text-[16px] roboto text-[#000000B2] max-w-[253px] opacity-[0.7] mt-[6px]">{t('products.95')}</p>
                            </div>  
                        </div>
                        <div className="flex items-center border-b border-[#CCCCCC]">
                            <div className="w-[300px]">
                                <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2]">{t('products.96')}</p>
                                <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] max-w-[253px] opacity-[0.7] mt-[6px]">{t('products.97')}</p>
                            </div>
                            <div className="w-px h-[200px] xl:h-[150px] bg-[#CCCCCC]"></div>
                            <div className="w-[300px]">
                                <p className="ml-[30px] manrope text-[20px] xl:text-[24px] font-[500] text-[#145B45]">{t('products.98')}</p>
                            </div>  
                        </div>
                        <div className="flex items-center border-b border-[#CCCCCC]">
                            <div className="w-[300px]">
                                <p className="text-[16px] xl:text-[20px] roboto text-[#000000B2]">{t('products.99')}</p>
                                <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] max-w-[253px] opacity-[0.7] mt-[6px]">{t('products.101')}</p>
                            </div>
                            <div className="w-px h-[200px] xl:h-[150px] bg-[#CCCCCC]"></div>
                            <div className="w-[300px]">
                                <p className="ml-[30px] manrope text-[20px] xl:text-[24px] font-[500] text-[#145B45]">{t('products.100')}</p>
                                <p className="ml-[30px] text-[14px] xl:text-[16px] roboto text-[#000000B2] max-w-[287px] opacity-[0.7] mt-[6px]">{t('products.102')}</p>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pricing;