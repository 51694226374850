import React from "react";

import { useTranslation, Trans } from "react-i18next";

import odesk from '../../img/Home/office-desk.png'

function GlobalOffices () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black mx-auto">{t('home.31')}</p>
                <img src={odesk} alt="" className="mt-[40px] mx-auto"/>
            </div>
        </div>
    );
}

export default GlobalOffices;