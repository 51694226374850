import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import sw1 from '../../img/Education/swiper/sw(1).png'
import sw2 from '../../img/Education/swiper/sw(2).png'
import sw3 from '../../img/Education/swiper/sw(3).png'
import sw4 from '../../img/Education/swiper/sw(4).png'
import sw5 from '../../img/Education/swiper/sw(5).png'

const data = [
    {
        name: "education.14",
        text: "education.15",
        sw: sw1,
    },
    {
        name: "education.16",
        text: "education.17",
        sw: sw2,
    },
    {
        name: "education.18",
        text: "education.19",
        sw: sw3,
    },
    {
        name: "education.20",
        text: "education.21",
        sw: sw4,
    },
    {
        name: "education.22",
        text: "education.23",
        sw: sw5,
    },
]

function Financial () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between items-end">
                    <div>
                        <p className="manrope text-[24px] xl:text-[48px] leading-[1.4]">{t('education.12')}</p>
                        <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] leading-[1.5] text-[#000000B2] mt-[20px]">{t('education.13')}</p>
                    </div>
                    <div className="mt-[20px]">
                        <div className='flex xl:gap-[20px]'>
                            <div onClick={() => swiper.slidePrev()} className="button_swiper cursor-pointer scale-75 xl:scale-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 12L5 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 5L5 12L12 19" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>  
                            </div>
                            <div onClick={() => swiper.slideNext()} className="button_swiper cursor-pointer scale-75 xl:scale-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12L19 12" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 19L19 12L12 5" stroke="#145B45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-[20px] xl:mt-[60px]">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            300: {
                            slidesPerView: 1,
                            },
                            1280: {
                            slidesPerView: 2
                            }
                        }}
                    >
                        {data.map((dat, index) => (
                            <SwiperSlide key={index}>
                                <div className="xl:w-[600px] bg-[#EBEBEB] rounded-[40px] p-[20px] xl:p-[40px] h-[650px] mx-auto">
                                    <img src={dat.sw} alt="" />
                                    <p className="mb-[6px] text-[#145B45] manrope text-[20px] xl:text-[32px] mt-[40px]">{t(dat.name)}</p>
                                    <p className="mt-[20px] text-[16px] text-[#000000B2] roboto leading-[1.2] opacity-[0.7]">{t(dat.text)}</p>
                                    <a href="https://user.asset-wealth.pro/signup" className="absolute bottom-[40px]">
                                        <div className="flex items-center gap-[16px]">
                                            <p className="roboto text-[14px] xl:text-[16px] text-[#145B45] font-[500]">{t('education.11')}</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect width="24" height="24" rx="12" fill="#145B45"/>
                                                <path d="M7 12L17 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13 7L18 12L13 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Financial;