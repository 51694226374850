import React from "react";

import { useTranslation, Trans } from "react-i18next";

import s1d from '../../img/Home/s1-desk.png'
import s2d from '../../img/Home/s2-desk.png'
import s3d from '../../img/Home/s3-desk.png'

import s1m from '../../img/Home/s1-mob.png'
import s2m from '../../img/Home/s2-mob.png'
import s3m from '../../img/Home/s3-mob.png'

function Still () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between xl:items-end">
                <div className="max-w-[600px]">
                    <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black">{t('home.19')}</p>
                </div>
                <div className="max-w-[397px] mt-[20px] xl:mt-0">
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('home.20')}</p>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col sm:flex-row justify-between mt-[20px] xl:mt-[60px]">
                <div className="xl:w-[498px] xl:h-[166px] w-full h-[106px] rounded-full bg-[#145B45] flex items-center justify-center">
                    <p className="text-center spanmainwhite text-white manrope text-[16px] xl:text-[32px] leading-[1.2]"><Trans>{t('home.21')}</Trans></p>
                </div>
                <div className="xl:w-[166px] xl:h-[166px] w-full h-[106px] rounded-full overflow-hidden mt-[20px] sm:mt-0">
                    <img src={s1d} alt="" className="hidden xl:block"/>
                    <img src={s1m} alt="" className="xl:hidden block w-full object-cover h-full"/>
                </div>
                <div className="xl:w-[498px] xl:h-[166px] w-full h-[106px] rounded-full bg-[#145B45] mt-[20px] sm:mt-0 flex items-center justify-center">
                    <p className="text-center spanmainwhite text-white manrope text-[16px] xl:text-[32px] leading-[1.2]"><Trans>{t('home.22')}</Trans></p>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col sm:flex-row justify-center gap-[20px] xl:gap-[60px] mt-[20px] xl:mt-[30px]">
                <div className="xl:w-[166px] xl:h-[166px] w-full h-[106px] rounded-full overflow-hidden">
                    <img src={s2d} alt="" className="hidden xl:block"/>
                    <img src={s2m} alt="" className="xl:hidden block w-full object-cover h-full"/>
                </div>
                <div className="xl:w-[498px] xl:h-[166px] w-full h-[106px] rounded-full bg-[#145B45] flex items-center justify-center">
                    <p className="text-center spanmainwhite text-white manrope text-[16px] xl:text-[32px] leading-[1.2]"><Trans>{t('home.23')}</Trans></p>
                </div>
                <div className="xl:w-[166px] xl:h-[166px] w-full h-[106px] rounded-full overflow-hidden">
                    <img src={s3d} alt="" className="hidden xl:block"/>
                    <img src={s3m} alt="" className="xl:hidden block w-full object-cover h-full"/>
                </div>
            </div>
        </div>
    );
}

export default Still;