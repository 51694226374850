import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/AccountTypes/main.png'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative mt-[10px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] p-[30px] xl:pt-[60px] xl:pl-[100px] flex flex-col bg-[#145B45] rounded-[40px] xl:h-[650px] relative">
                <p className="spanmainwhite text-white manrope text-[32px] xl:text-[64px] uppercase leading-[1.2] font-[600]"><Trans>{t('accounttypes.1')}</Trans></p>
                <p className="mt-[10px] roboto text-[#FFFFFFB2] text-[16px] xl:text-[20px]">{t('accounttypes.2')}</p>
                <p className="max-w-[497px] roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2] opacity-[0.7] mt-[20px] xl:mt-[40px]">{t('accounttypes.3')}</p>
                <img src={main} alt="" className="mt-[20px] xl:mt-0 xl:absolute right-0"/>
            </div>
        </div>
    );
}

export default Main;