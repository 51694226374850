import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import wt from '../../img/Products/whytrade.png'

import button from '../../img/Products/button.png'

const faqData = [
    {
        question: 'products.76',
        answer: 'products.77'
    },
    {
        question: 'products.78',
        answer: 'products.79'
    },
    {
        question: 'products.80',
        answer: 'products.81'
    },
    {
        question: 'products.82',
        answer: 'products.83'
    },
    {
        question: 'products.84',
        answer: 'products.85'
    },
]

function WhyTrade () {

    const { t } = useTranslation();
      
    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative">
                <p className="manrope leading-[1.2] text-[24px] xl:text-[48px] text-black spanmain"><Trans>{t('products.75')}</Trans></p>
                <div className="flex flex-col-reverse md:flex-row justify-between mt-[20px] xl:mt-[40px]">
                    <div className="xl:w-[600px]">
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div className={`flex justify-between mt-[20px] transition-all duration-300 ${openIndex === index ? 'h-auto' : 'h-[70px]'}`}>
                                    <div className='mx-[25px]'>
                                        <p className='mt-[15px] manrope text-[14px] xl:text-[20px] text-[#145B45] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                            <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'>{t(faq.answer)}</p>
                                        )}
                                    </div>
                                    <div className='cursor-pointer mx-[25px] mt-[18px] flex-shrink-0' onClick={() => toggleText(index)}>
                                        <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-45' : 'transition-transform'}`}/>
                                    </div>
                                </div>
                                <div className="h-[1px] w-full bg-[#00000033]"></div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <img src={wt} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyTrade;